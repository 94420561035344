<template>
    <cart/>
</template>

<script>
import {mapActions} from "vuex";
import Cart from "@/components/cart/Cart";

export default {
    name: "SubdivisionCart",
    components: {
        Cart
    },
    mounted() {
        this.getCartInfo();
        this.getUserInfo();
    },
    methods: {
        ...mapActions('cart/subdivision', ['getCartInfo', 'getUserInfo'])
    },
    metaInfo() {
        return {
            title: `Корзина - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>

</style>
